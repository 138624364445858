import { TFunction } from "i18next";
import { FooterBlockDto } from "~/modules/pageBlocks/components/blocks/marketing/footer/FooterBlockUtils";
import { defaultSocials } from "./defaultSocials";
import { DataSourceTypes } from "~/modules/datalang/dtos/DataSourceType";

export function defaultFooter({ t }: { t: TFunction }): FooterBlockDto {
  return {
    style: "columns",
    text: "",
    withLanguageSelector: true,
    withDarkModeToggle: true,
    withThemeSelector: true,
    sections: [
      {
        name: t("front.footer.application"),
        items: [
          // { name: t("front.hero.demo"), href: "/demo" },
          { name: t("front.footer.pricing"), href: "/pricing" },
          { name: t("front.footer.signIn"), href: "/login" },
          { name: t("front.footer.blog"), href: "/blog" },
          { name: t("front.footer.contact"), href: "/contact" },
          { name: t("front.footer.newsletter"), href: "/newsletter" },
        ],
      },
      {
        name: t("front.footer.product"),
        items: [
          { name: t("front.navbar.affiliates"), href: "/affiliate-program" },
          { name: t("front.footer.changelog"), href: "/changelog" },
          { name: t("front.footer.termsAndConditions"), href: "/terms-and-conditions" },
          { name: t("front.footer.privacyPolicy"), href: "/privacy-policy" },
        ],
      },
      {
        name: t("front.footer.features"),
        items: [
          { name: "Database to API", href: "/docs/articles/database-to-api" },
          { name: "Chat with your Database", href: "/docs/articles/chat-with-your-databases" },
          { name: "Publish a custom GPT", href: "/docs/articles/publish-a-custom-gpt-from-your-data" },
        ],
      },
      {
        name: t("models.dataSource.plural"),
        items: DataSourceTypes.filter((f) => f.demoSlug && !f.underConstruction).map((f) => ({
          href: `/chat-with/${f.demoSlug}`,
          name: t(`datalang.dataSourceTypes.${f.value}`),
          // hint: f.underConstruction ? t("shared.underConstruction") : undefined,
          dataSourceType: f.value,
          desktopOnly: true,
        })),
      },
    ],
    socials: defaultSocials,
  };
}
