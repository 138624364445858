import { SocialsBlockDto } from "~/modules/pageBlocks/components/blocks/shared/socials/SocialsBlockDto";

export const defaultSocials: SocialsBlockDto = {
  instagram: "https://www.instagram.com/datalang.io",
  twitter: "https://twitter.com/DatalangAI",
  github: "https://github.com/AlexandroMtzG",
  discord: "",
  twitterCreator: "https://twitter.com/AlexandroMtzG",
  youtube: "https://www.youtube.com/@datalangai",
};
